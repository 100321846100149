import {call, put, takeLatest}from 'redux-saga/effects'
import { DispatchInfoKiemThucBaBuocToStore, DispatchItemCoSoSanXuatToStore, DispatchItemKiemThucBaBuocToStoreInDate, DispatchListKiemThucBaBuoc, DispatchlistTypeOfKiemThucToStore, DispatchUpdateKiemThucBaBuoc } from '../action/kiemThuc'
import { AddKiemThucApi, UpdateKiemThucApi,AddKiemThucBaBuocApi, GetKiemThucBaBuocApi, UpdateKiemThucBaBuocApi, AddInfoKiemThucBaBuocApi, GetInfoKiemThucBaBuocApi, AddCoSoSanXuatApi, UpdateCoSoSanXuatApi } from '../api/kiemThuc'
import { ADD_INFO_KIEM_THUC_BA_BUOC, ADD_KIEM_THUC, ADD_KIEM_THUC_BA_BUOC, GET_LIST_KIEM_THUC_BA_BUOC_IN_MONTH, UPDATE_KIEM_THUC_BA_BUOC, UPDATE_LIST_TYPE_OF_KIEM_THUC ,GET_INFO_KIEM_THUC_BA_BUOC,ADD_CO_SO_SAN_XUAT, UPDATE_ITEM_CO_SO_SAN_XUAT} from '../contant/KiemThuc'

const SUCCESS = "success"

function* AddKiemThucSaga({payload}){
        let res = yield call(AddKiemThucApi,payload.item)
        if(res.data.result===SUCCESS){
               yield put(DispatchlistTypeOfKiemThucToStore(res.data.data))
        }
}

function* UpdateListTypeOfKiemThucSaga({payload}){
        let res = yield call(UpdateKiemThucApi,payload.item)
        if(res.data.result===SUCCESS){
                //yield put(DispatchlistTypeOfKiemThucToStore(res.data.data))
         }
}

function* AddKiemThucBaBuocSaga({payload}){
        let res = yield call(AddKiemThucBaBuocApi,payload.item)
            yield put(DispatchItemKiemThucBaBuocToStoreInDate())
}
 
function* GetListKiemThucBaBuocInMonthSaga({payload}){      
        let res = yield call(GetKiemThucBaBuocApi,payload.item)
        if(res.data.result===SUCCESS){
             yield put(DispatchListKiemThucBaBuoc(res.data.data))
        }
}

function* UpdateKiemThucBaBuocSaga({payload}){
       // console.log(payload.item)
       if(payload.item!==undefined){
                let res =  yield call(UpdateKiemThucBaBuocApi,payload.item)
                if(res.data.result===SUCCESS){
                yield put(DispatchUpdateKiemThucBaBuoc(res.data.data))
       }
       
   }
}

function* AddInfoKiemThucBaBuocSaga({payload}){
        let res = yield call(AddInfoKiemThucBaBuocApi,payload.item)
        if(res.data.result===SUCCESS){
                yield put(DispatchInfoKiemThucBaBuocToStore(res.data.data))
        }
}

function* GetInfoKiemThucBaBuocSaga({payload}){
        let res = yield call(GetInfoKiemThucBaBuocApi,payload.item)
        if(res.data.result===SUCCESS&&res.data.data!==null){
                yield put(DispatchInfoKiemThucBaBuocToStore(res.data.data))
        }
}

function* AddCoSoSanXuatSaga({payload}){
        let res = yield call(AddCoSoSanXuatApi,payload.item)
        if(res.data.result===SUCCESS){
                yield put(DispatchItemCoSoSanXuatToStore(res.data.data))
        }
}

function* UpdateItemCoSoSanXuatSaga({payload}){
        let res = yield call(UpdateCoSoSanXuatApi,payload.item)
        if(res.data.result===SUCCESS){
                yield put(DispatchItemCoSoSanXuatToStore(res.data.data))                
        }
}


function* KiemThucSaga(){
   yield takeLatest(ADD_KIEM_THUC,AddKiemThucSaga)
   yield takeLatest(UPDATE_LIST_TYPE_OF_KIEM_THUC,UpdateListTypeOfKiemThucSaga)
   yield takeLatest(ADD_KIEM_THUC_BA_BUOC,AddKiemThucBaBuocSaga)
   yield takeLatest(GET_LIST_KIEM_THUC_BA_BUOC_IN_MONTH,GetListKiemThucBaBuocInMonthSaga)
   yield takeLatest(UPDATE_KIEM_THUC_BA_BUOC,UpdateKiemThucBaBuocSaga)
   yield takeLatest(ADD_INFO_KIEM_THUC_BA_BUOC,AddInfoKiemThucBaBuocSaga)
   yield takeLatest(GET_INFO_KIEM_THUC_BA_BUOC,GetInfoKiemThucBaBuocSaga)
   yield takeLatest(ADD_CO_SO_SAN_XUAT,AddCoSoSanXuatSaga)
   yield takeLatest(UPDATE_ITEM_CO_SO_SAN_XUAT,UpdateItemCoSoSanXuatSaga)
}

export default KiemThucSaga